<template>
    <div>
        <b-card border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Identificación riesgo cliente</h5>
                <b-button v-if="!disabled && can('delete_gei_master_delivery_installation_client_ee')"
                    variant="outline-success"
                    @click="addRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "

                    />
                </b-button>

            </div>
            <form-base-document
                :documents-state-data="documents_client_installation_risk"
                :documents-columns="columns"
                :documents-route="documents_by_route_client_installation_risk"
                :context="'client-installation-risk'"
                :is-disabled="disabled"
                :create-permission="can('create_gei_master_identification_risk')"
                :delete-permission="can('delete_gei_master_identification_risk')"
            />

        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import formBaseDocument
    from "@/views/gei/masters_installations/form/generalDataAndRisks/components/base/formBaseDocument"

const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocuments/getField',
    mutationType: 'masterInstallationDocuments/updateField',
})

export default {
    name: "FormGeneralDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument
    },
    props: {
        disabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'effective_date', label: 'Fecha Realización', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('masterInstallation', ['master_installation']),
        ...mapState('masterInstallationDocuments', []),
        ...mapFields({
            documents_client_installation_risk: 'documents_client_installation_risk',
            documents_by_route_client_installation_risk: 'documents_by_route_client_installation_risk',
        }),
    },
    mounted() {
        this.context = 'client-installation-risk'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                effective_date: null,
                route: this.master_installation.installation_code + '/IDENTIFICACION_RIESGOS/Cliente',
                observations: null,
                options: null,
                master_installation_id: this.master_installation.id,
            }

            this.documents_client_installation_risk.push(newRow)
            this.documents_client_installation_risk.editedRow = newRow
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir] .modal .modal-header .close {
    box-shadow: none;
    transform: unset;
    background: none;
}
.table-container {
    max-height: 400px; /* Ajuste a altura máxima desejada */
    overflow-y: auto;
}
</style>