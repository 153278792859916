<template>
    <div>
        <b-form-group label="Figura RD171" label-for="figure_id">
            <v-select
                v-model="figureId"
                class="pt-0" label="name" name="figure_id"
                :reduce="(option) => option.id" :disabled="disabled"
                :options="figures"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        vSelect,
        BFormGroup,
    },
    props: {
        figureId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localFigureId: this.figureId,
            figures: [
                { 'id' : 1, 'name': 'MANTENEDOR' },
                { 'id' : 2, 'name': 'PROPIETARIO' },
                { 'id' : 3, 'name': 'TITULAR' }
            ]
        }
    },
    watch: {
        figureId(newVal) {
            this.localFigureId = newVal
            this.$emit('update', { figure_id: this.localFigureId })
        },
    },
}
</script>
