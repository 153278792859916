<template>
    <div>
        <b-form-group label="Provincia" label-for="province">
            <v-select
                v-model="provinceId"
                class="pt-0" label="province" name="province"
                :reduce="(option) => option.province_id" :disabled="disabled"
                :options="provinces_countries"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapFields} from "vuex-map-fields"
import {mapState} from "vuex"


export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        provinceId: {
            type: Number,
            default: null
        },
        countryId: {
          type: Number,
          default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            provincesList: [],
            localProvinceId: this.provinceId,
            localCountryId: this.countryId,
        }
    },
    computed: {
        ...mapState('provincesCountries', []),
        ...mapState('masterInstallation', ['masters_installations']),
        ...mapFields({
            provinces_countries: 'provincesCountries.provinces',
        }),
    },
    watch: {
        provinceId(newVal) {
            this.localProvinceId = newVal
            this.$emit('update', { province_id: this.localProvinceId })
        },
    },
    mounted() {
        this.getAllProvincesCountries(this.localCountryId)
    },
    methods:{
        getAllProvincesCountries(countryId) {
            this.showLoading = true

            this.$store.dispatch('provincesCountries/getAllProvincesByCountry',{
                countryId: countryId,
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },
}
</script>
