<template>
    <div>
        <b-form-group label="Longitud" label-for="client">
            <b-form-input v-model="longitude" placeholder="Longitud" :disabled="disabled"
                          :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        longitude: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localLongitudeInput: this.longitude,
        }
    },
    watch: {
        longitude(newVal) {
            this.localLongitudeInput = newVal
            this.$emit('update', { longitude: this.localLongitudeInput })
        },
    },
}
</script>
