<template>
    <div>
        <b-form-group label="Tipo Cliente" label-for="installation_type">
            <v-select
                v-model="clientTypeId"
                class="pt-0" label="type" name="type"
                :reduce="(option) => option.id"
                :options="clientTypesList"
                :disabled="disabled"
            />
            <small v-if="validation" className="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        clientTypeId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            clientTypesList: [],
            localClientTypeId: this.clientTypeId,
        }
    },
    watch: {
        clientTypeId(newVal) {
            this.localClientTypeId = newVal
            this.$emit('update', { client_type_id: this.localClientTypeId })
        },
    },
    mounted() {
        this.getClientTypes()
    },
    methods: {
        getClientTypes() {
            this.showLoading = true

            this.$store.dispatch('clientType/getAllClientTypes')
            .then(response => {
                this.clientTypesList = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },
}
</script>
