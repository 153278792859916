<template>
    <div>
        <b-form-group label-for="dias" class="mt-2">
            <b-form-group class="form-check form-check-inline">
                <b-form-checkbox v-model="active" :value="1" :disabled="disabled">
                    Instalación Activa
                </b-form-checkbox>
            </b-form-group>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormCheckbox,
    },
    props: {
        active: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localActive: this.active,
        }
    },
    watch: {
        active(newVal) {
            this.localActive = newVal
            this.$emit('update', { active: this.localActive })
        },
    },
}
</script>
