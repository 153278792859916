<template>
    <div>
        <b-form-group label="Observaciones" label-for="observations">
            <b-form-textarea id="observations" v-model="localObservations" rows="4" :disabled="disabled"
                             placeholder="Observaciones" autocomplete="off"
                             :class="validation ? 'is-invalid': ''"/>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormTextarea} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormTextarea,
    },
    props: {
        observations: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localObservations: {
            get() {
                return this.observations
            },
            set(value) {
                this.$emit('update', {observations: value})
            },
        },
    },
}
</script>
