<template>
    <div>
        <b-form-group label="Fecha Fin Explotación" label-for="date_end">
            <flat-pickr
                v-model="dateEnd"
                class="form-control"
                autocomplete="off"
                name="date_service_partial"
                :disabled="disabled"
                :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y'}"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'

import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BFormGroup,
        flatPickr
    },
    props: {
        dateEnd: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            SpanishLocale,
            localDateEnd: this.dateEnd,
        }
    },
    watch: {
        dateEnd(newVal) {
            this.localDateEnd = newVal
            this.$emit('update', { end_date: this.localDateEnd })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>