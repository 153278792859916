<template>
    <div>
        <b-form-group label-for="dias" class="mt-2 ml-5">
            <b-form-group class="form-check form-check-inline">
                <b-form-checkbox v-model="draft" :value="1" :disabled="disabled">
                    Borrador
                </b-form-checkbox>
            </b-form-group>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormCheckbox,
    },
    props: {
        draft: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localDraft: this.draft,
        }
    },
    watch: {
        draft(newVal) {
            this.localDraft = newVal
            this.$emit('update', { draft: this.localDraft })
        },
    },
}
</script>
