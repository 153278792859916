<template>
    <div>
        <table class="w-100 table b-table">
            <tr>
                <th>
                    Nombre
                </th>
                <th>
                    Tipo de instalación
                </th>
                <th>
                    Fecha de inicio
                </th>
                <th>
                    Fecha de fin
                </th>
                <th>
                </th>
            </tr>
            <tr v-for="childrenInstallation in children_installations" :key="childrenInstallation.id">
                <td class="pt-1">
                    {{ childrenInstallation.gei_code }}
                </td>
                <td class="pt-1">
                    {{ childrenInstallation.installation_type ? childrenInstallation.installation_type.type : '' }}
                </td>
                <td class="pt-1">
                    {{ helpers.formatDate(childrenInstallation.start_date) }}
                </td>
                <td class="pt-1">
                    {{ helpers.formatDate(childrenInstallation.end_date) }}
                </td>
                <td class="pt-1">
                    <router-link :to="{ name: 'children_installations_gei_edit', params: { id: childrenInstallation.id} }" target="_blank">
                        <b-button variant="primary">
                            Ver instalación
                        </b-button>
                    </router-link>

                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import {createHelpers} from "vuex-map-fields"


const {mapFields} = createHelpers({
    getterType: 'childrenInstallation/getField',
    mutationType: 'childrenInstallation/updateField',
})

export default {
    name: "ListChildrenInstallations",
    components: {
        BButton,
    },
    data() {
        return {
            helpers
        }
    },
    computed: {
        ...mapState('childrenInstallation', []),
        ...mapFields({
            children_installations: 'children_installations',
        }),
    },
    mounted() {
        this.getChildrenInstallations()
    },
    methods:{
        getChildrenInstallations() {
            this.showLoading = true

            this.$store.dispatch('childrenInstallation/listChildrenInstallationsByMaster', {
                id: this.$route.params.id,
            })
            .then(response => {
                this.childrenInstallations = response
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
    }

}
</script>

<style scoped>

</style>