<template>
    <b-form-group label="Código GEI">
        <b-row sm="12">
            <b-col sm="1">
                <b-form-group label="">
                    <b-form-input autocomplete="off" disabled="disabled" value="EE"/>
                </b-form-group>
            </b-col>
            <b-col sm="1">
                <b-form-group label="">
                    <b-form-input v-model="localSegmentCode" autocomplete="off"
                                  disabled="disabled"/>
                </b-form-group>
            </b-col>
            <b-col sm="1">
                <b-form-group label="">
                    <b-form-input v-model="installationId" autocomplete="off" disabled="disabled" :value="installationId !== '---' ? '---' : installationId " />
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="">
                    <b-form-input v-model="localInstallationTypeCode" autocomplete="off" disabled="disabled"
                                 />
                </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group label="">
                    <b-form-input v-model="localInstallationCode" autocomplete="off"/>
                </b-form-group>
            </b-col>
            <b-col xl="12">
                <small v-if="validation" class="text-danger">
                    {{ validation }}</small>
            </b-col>
        </b-row>
    </b-form-group>

</template>

<script>
import {BFormGroup, BFormInput, BCol, BRow} from 'bootstrap-vue'
export default {
    components: {
        BFormGroup,
        BFormInput,
        BCol,
        BRow
    },
    props: {
        segmentCode: {
            type: String,
            default: ''
        },
        installationTypeCode: {
            type: String,
            default: ''
        },
        installationCode: {
            type: String,
            default: ''
        },
        installationId: {
            default: '---'
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localSegmentCode: {
            get() {
                return this.segmentCode
            },
            set(value) {
                this.$emit('update', {segment_code: value})
            },
        },
        localInstallationTypeCode: {
            get() {
                return this.installationTypeCode
            },
            set(value) {
                this.$emit('update', {installation_type_code: value})
            },
        },
        localInstallationCode: {
            get() {
                return this.installationCode
            },
            set(value) {
                this.$emit('update', {installation_code: value})
                this.$emit('update', {gei_code: value})
            },
        },
    },
}
</script>
