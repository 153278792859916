<template>
    <div>
        <b-form-group label="Dirección" label-for="company">
            <b-form-input v-model="address" placeholder="Dirección" autocomplete="off" :disabled="disabled"
                          :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        address: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localAddress: this.address,
        }
    },
    watch: {
        address(newVal) {
            this.localAddress = newVal
            this.$emit('update', { address: this.localAddress })
        },
    },
}
</script>
