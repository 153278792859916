<template>
    <div>
        <b-form-group label="Fecha Cierre" label-for="date_end">
            <flat-pickr
                v-model="dateClosing"
                class="form-control"
                autocomplete="off"
                name="date_service_partial"
                :disabled="disabled"
                :config="{ dateFormat: 'd-m-Y', locale: SpanishLocale, allowInput: true, altInput: true, altFormat: 'd-m-Y'}"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'

import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BFormGroup,
        flatPickr
    },
    props: {
        dateClosing: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            SpanishLocale,
            localDateClosing: this.dateClosing,
        }
    },
    watch: {
        dateClosing(newVal) {
            this.localDateClosing = newVal
            this.$emit('update', { closing_date: this.localDateClosing })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>