<template>
    <div>
        <b-form-group label="Código Postal" label-for="postal">
            <b-form-input v-model="postalCode" placeholder="Código Postal" autocomplete="off" :disabled="disabled"
                          :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        postalCode: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localPostalCode: this.postalCode,
        }
    },
    watch: {
        postalCode(newVal) {
            this.localPostalCode = newVal
            this.$emit('update', { postal_code: this.localPostalCode })
        },
    },
}
</script>
