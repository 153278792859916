<template>
    <div>
        <b-form-group label="Gestor Responsable" label-for="installation_type">
            <v-select
                v-model="responsibleId"
                class="pt-0" label="name" name="name"
                :reduce="(option) => option.id"
                :options="responsiblesList"
                :disabled="disabled"
            />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>
<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        responsibleId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            responsiblesList: [],
            localResponsibleId: this.responsibleId,
        }
    },
    watch: {
        responsibleId(newVal) {
            this.localResponsibleId = newVal
            this.$emit('update', { responsible_id: this.localResponsibleId })
        },
    },
    mounted() {
        this.getResponsibles()
    },
    methods:{
        getResponsibles() {
            this.showLoading = true

            this.$store.dispatch('responsibles/getAllResponsibles')
            .then(response => {
                this.responsiblesList = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },

}
</script>
