<template>
    <div>
        <b-form-group label="Gestor Proyectos" label-for="installation_type">
            <v-select
                v-model="projectResponsibleId"
                class-name="pt-0" label="name" name="name"
                :reduce="(option) => option.id"
                :options="projectResponsiblesList"
                :disabled="disabled"
            />
            <small v-if="validation" className="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        BFormGroup,
        vSelect,
    },
    props: {
        projectResponsibleId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            projectResponsiblesList: [],
            localProjectResponsibleId: this.projectResponsibleId,
        }
    },
    watch: {
        projectResponsibleId(newVal) {
            this.localProjectResponsibleId = newVal
            this.$emit('update', { project_responsible_id: this.localProjectResponsibleId })
        },
    },
    mounted() {
        this.getProjectResponsibles()
    },
    methods: {
        getProjectResponsibles() {
            this.showLoading = true

            this.$store.dispatch('projectResponsible/getAllProjectResponsibles')
            .then(response => {
                this.projectResponsiblesList = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },
}
</script>
