<template>
    <div>
        <b-card border-variant="primary" class="p-0">
            <b-row>
                <b-col xl="2" lg="4" cols="6">
                    <installation-active-checkbox :active="master_installation.active" :disabled="isDisabled"
                                                  @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3" lg="4" cols="6">
                    <draft-checkbox :draft="master_installation.draft" :disabled="isDisabled"
                                    @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3" lg="4">
                    <company-input :company-id="master_installation.company_id" :disabled="isDisabled"
                                   :validation="validation_master_installation.company_id"
                                   @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3" lg="4">
                    <proceeding-code-input :proceeding-code="master_installation.proceeding_code" :disabled="isDisabled"
                                           :validation="validation_master_installation.proceeding_code"
                                           @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>

        <b-card border-variant="primary">
            <b-row xl="12">
                <b-col xl="4">
                    <client-input :client="master_installation.client" :disabled="isDisabled"
                                  :validation="validation_master_installation.client" @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <client-cif-input :client-cif="master_installation.client_cif" :disabled="isDisabled"
                                      :validation="validation_master_installation.client_cif"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <installation-type-id-select :installation-type-id="master_installation.installation_type_id"
                                                 :disabled="isDisabled"
                                                 :validation="validation_master_installation.installation_type_id"
                                                 @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <insured-value-input :insured-value="master_installation.insured_value" :disabled="isDisabled"
                                         :validation="validation_master_installation.insured_value"
                                         @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row xl="12">
                <!--                <b-col xl="2">
                                    <b-form-group label="Segmento" label-for="segment">
                                        <v-select
                                            v-model="master_installation.segment_id"
                                            class="pt-0" label="segment" name="segment_id"
                                            :reduce="(option) => option.id"
                                            :options="segmentsList"
                                        />
                                        <xlall v-if="validation_master_installation.segment_id" class="text-danger">
                                            {{ validation_master_installation.segment_id }}</xlall>
                                    </b-form-group>
                                </b-col>-->

                <!--                <b-col xl="3">
                                    <b-form-group label="Titularidad Industria" label-for="industry_ownership_id">
                                        <v-select
                                            v-model="master_installation.industry_ownership_id"
                                            class="pt-0" label="type" name="type"
                                            :reduce="(option) => option.id"
                                            :options="industryOwnershipsList"
                                        />
                                        <xlall v-if="validation_master_installation.industry_ownership_id" class="text-danger">
                                            {{ validation_master_installation.industry_ownership_id }}</xlall>
                                    </b-form-group>
                                </b-col>-->
                <b-col xl="2">
                    <date-start-flat-pickr :date-start="master_installation.start_date" :disabled="isDisabled"
                                           :validation="validation_master_installation.start_date"
                                           @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <date-end-flat-pickr :date-end="master_installation.end_date" :disabled="isDisabled"
                                         :validation="validation_master_installation.end_date"
                                         @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="1">
                    <date-closing-flat-pickr :date-closing="master_installation.closing_date" :disabled="isDisabled"
                                         :validation="validation_master_installation.closing_date"
                                         @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <period-description-input :period-description="master_installation.period_description"
                                              :validation="validation_master_installation.period_description"
                                              @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <level2-id-select :level2id="master_installation.level_2_id"
                                      :validation="validation_master_installation.level_2_id"
                                      @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3 ">
                    <responsible-id-select :responsible-id="master_installation.responsible_id"
                                           :validation="validation_master_installation.responsible_id"
                                           @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row xl="12">
                <b-col xl="4">
                    <client-contact-person-input :client-contact-person="master_installation.client_contact_person"
                                                 :validation="validation_master_installation.client_contact_person"
                                                 @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="2">
                    <client-phone-input :client-phone="master_installation.client_phone"
                                        :validation="validation_master_installation.client_phone"
                                        @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <client-email-input :client-email="master_installation.client_email"
                                        :validation="validation_master_installation.client_email"
                                        @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <project-responsible-id-select :project-responsible-id="master_installation.project_responsible_id"
                                                   :validation="validation_master_installation.project_responsible_id"
                                                   @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <!--        <b-card border-variant="primary">
                    <b-row cols="12">
        &lt;!&ndash;                <b-col xl="3">
                            <b-form-group label="Nível 1" label-for="level_1_id">
                                <v-select
                                    v-model="master_installation.level_1_id"
                                    class="pt-0" label="name" name="name"
                                    :reduce="(option) => option.id"
                                    :options="levels1List"
                                />
                                <xlall v-if="validation_master_installation.level_1_id" class="text-danger">
                                    {{ validation_master_installation.level_1_id }}</xlall>
                            </b-form-group>
                        </b-col>&ndash;&gt;

        &lt;!&ndash;                <b-col xl="3">
                            <b-form-group label="Nível 3" label-for="level_3_id">
                                <v-select
                                    v-model="master_installation.level_3_id"
                                    class="pt-0" label="name" name="name"
                                    :reduce="(option) => option.id"
                                    :options="levels3List"
                                />
                                <xlall v-if="validation_master_installation.level_3_id" class="text-danger">
                                    {{ validation_master_installation.level_3_id }}</xlall>
                            </b-form-group>
                        </b-col>&ndash;&gt;
        &lt;!&ndash;                <b-col xl="3">
                            <b-form-group label="Nível 4" label-for="level_4_id">
                                <v-select
                                    v-model="master_installation.level_4_id"
                                    class="pt-0" label="name" name="name"
                                    :reduce="(option) => option.id"
                                    :options="levels4List"
                                />
                                <xlall v-if="validation_master_installation.level_4_id" class="text-danger">
                                    {{ validation_master_installation.level_4_id }}</xlall>
                            </b-form-group>
                        </b-col>&ndash;&gt;
                    </b-row>
                </b-card>-->
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col xl="3">
                    <client-type-id-input :client-type-id="master_installation.client_type_id"
                                          :validation="validation_master_installation.client_type_id"
                                          @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <city-input :city="master_installation.city" :validation="validation_master_installation.city"
                                @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <country-id-select :country-id="master_installation.country_id"
                                       :validation="validation_master_installation.country_id"
                                       @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <postal-code-input :postal-code="master_installation.postal_code"
                                       :validation="validation_master_installation.postal_code"
                                       @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row cols="12">
                <b-col xl="3">
                    <province-id-select :province-id="master_installation.province_id"
                                        :country-id="master_installation.country_id"
                                        :validation="validation_master_installation.province_id"
                                        @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <latitude-input :latitude="master_installation.latitude"
                                    :validation="validation_master_installation.latitude" @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <longitude-input :longitude="master_installation.longitude"
                                     :validation="validation_master_installation.longitude"
                                     @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="3">
                    <figure-id-select :figure-id="master_installation.figure_id"
                                      :validation="validation_master_installation.figure_id"
                                      @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col xl="6">
                    <autonomous-community-input :autonomous-community="master_installation.autonomous_community"
                                 :validation="validation_master_installation.autonomous_community" @update="handleUpdateFields"/>
                </b-col>
                <b-col xl="6">
                    <address-input :address="master_installation.address"
                                 :validation="validation_master_installation.address" @update="handleUpdateFields"/>
                </b-col>
            </b-row>
        </b-card>
        <b-card border-variant="primary">
            <b-row cols="12">
                <b-col xl="12">
                    <observations-text-area :observations="master_installation.observations"
                                            :validation="validation_master_installation.observations"
                                            @update="handleUpdateFields" />
                </b-col>
                <!--                <b-col xl="3">
                                    <b-form-group label="Território OPSVA" label-for="territory_opsva_id">
                                        <v-select
                                            v-model="master_installation.territory_opsva_id"
                                            class="pt-0" label="name" name="name"
                                            :reduce="(option) => option.id"
                                            :options="opsvaTerritoriesList"
                                        />
                                        <xlall v-if="validation_master_installation.territory_opsva_id" class="text-danger">
                                            {{ validation_master_installation.territory_opsva_id }}</xlall>
                                    </b-form-group>
                                </b-col>-->
                <!--                <b-col xl="3">
                                    <b-form-group label-for="dias" class="mt-2 ml-5">
                                        <b-form-group class="form-check form-check-inline">
                                            <b-form-checkbox v-model="master_installation.ccpp" :value="1">
                                                CCPP
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-form-group>
                                </b-col>-->
            </b-row>
        </b-card>
        <b-col class="d-flex justify-content-end">
            <b-button v-if="!edit && can('create_gei_master')" class="col-xl-2" variant="outline-success" block @click="createInstallation()">
                Crear
            </b-button>
            <b-button v-if="edit && can('edit_gei_master')" class="col-xl-2" variant="outline-success" block @click="updateInstallation()">
                Guardar
            </b-button>
        </b-col>
    </div>
</template>

<script>
import {BCard, BCol, BRow, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import helper from "@/resources/helpers/helpers"

//components
import installationActiveCheckbox
    from "@/views/gei/masters_installations/form/formInstallationData/components/installationActiveCheckbox"
import draftCheckbox from "@/views/gei/masters_installations/form/formInstallationData/components/draftCheckbox"
import companyInput from "@/views/gei/masters_installations/form/formInstallationData/components/companyInput"
import proceedingCodeInput
    from "@/views/gei/masters_installations/form/formInstallationData/components/proceedingCodeInput"
import clientInput from "@/views/gei/masters_installations/form/formInstallationData/components/clientInput"
import clientCifInput from "@/views/gei/masters_installations/form/formInstallationData/components/clientCifInput"
import installationTypeIdSelect
    from "@/views/gei/masters_installations/form/formInstallationData/components/installationTypeIdSelect"
import insuredValueInput from "@/views/gei/masters_installations/form/formInstallationData/components/insuredValueInput"
import dateStartFlatPickr
    from "@/views/gei/masters_installations/form/formInstallationData/components/dateStartFlatPickr"
import dateEndFlatPickr from "@/views/gei/masters_installations/form/formInstallationData/components/dateEndFlatPickr"
import dateClosingFlatPickr from "@/views/gei/masters_installations/form/formInstallationData/components/dateClosingFlatPickr"
import periodDescriptionInput
    from "@/views/gei/masters_installations/form/formInstallationData/components/periodDescriptionInput"
import responsibleIdSelect
    from "@/views/gei/masters_installations/form/formInstallationData/components/responsibleIdSelect"
import clientContactPersonInput
    from "@/views/gei/masters_installations/form/formInstallationData/components/clientContactPersonInput"
import clientPhoneInput from "@/views/gei/masters_installations/form/formInstallationData/components/clientPhoneInput"
import clientEmailInput from "@/views/gei/masters_installations/form/formInstallationData/components/clientEmailInput"
import projectResponsibleIdSelect
    from "@/views/gei/masters_installations/form/formInstallationData/components/projectResponsibleIdSelect"
import clientTypeIdInput from "@/views/gei/masters_installations/form/formInstallationData/components/clientTypeIdInput"
import cityInput from "@/views/gei/masters_installations/form/formInstallationData/components/cityInput"
import countryIdSelect from "@/views/gei/masters_installations/form/formInstallationData/components/countryIdSelect"
import postalCodeInput from "@/views/gei/masters_installations/form/formInstallationData/components/postalCodeInput"
import provinceIdSelect from "@/views/gei/masters_installations/form/formInstallationData/components/provinceIdSelect"
import latitudeInput from "@/views/gei/masters_installations/form/formInstallationData/components/latitudeInput"
import longitudeInput from "@/views/gei/masters_installations/form/formInstallationData/components/longitudeInput"
import figureIdSelect from "@/views/gei/masters_installations/form/formInstallationData/components/figureIdSelect"
import addressInput from "@/views/gei/masters_installations/form/formInstallationData/components/addressInput"
import autonomousCommunityInput from "@/views/gei/masters_installations/form/formInstallationData/components/autonomousCommunityInput.vue"
import observationsTextArea
    from "@/views/gei/masters_installations/form/formInstallationData/components/observationsTextArea"
import level2IdSelect from "@/views/gei/masters_installations/form/formInstallationData/components/level2IdSelect"
import {GEI_MASTERS_INSTALLATIONS_LIST} from "@/router/web/constants"

const {mapFields} = createHelpers({
    getterType: 'masterInstallation/getField',
    mutationType: 'masterInstallation/updateField',
})
export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BButton,


        //componentsForm
        installationActiveCheckbox,
        draftCheckbox,
        companyInput,
        proceedingCodeInput,
        clientInput,
        clientCifInput,
        installationTypeIdSelect,
        insuredValueInput,
        dateStartFlatPickr,
        dateEndFlatPickr,
        dateClosingFlatPickr,
        periodDescriptionInput,
        level2IdSelect,
        responsibleIdSelect,
        clientContactPersonInput,
        clientPhoneInput,
        clientEmailInput,
        projectResponsibleIdSelect,
        clientTypeIdInput,
        cityInput,
        countryIdSelect,
        postalCodeInput,
        provinceIdSelect,
        latitudeInput,
        longitudeInput,
        figureIdSelect,
        addressInput,
        autonomousCommunityInput,
        observationsTextArea
    },
    data() {
        return {
            SpanishLocale,
            edit: false,
            showLoading: false,
            isDisabled: false,
        }
    },
    computed: {
        ...mapState('masterInstallation', ['validation_master_installation']),
        ...mapFields({
            master_installation: 'master_installation',
        }),
    },
    watch: {
      'master_installation.country_id': function(newCountryId, oldCountryId) {
        if (newCountryId !== oldCountryId) {
          console.log('Watch ' + newCountryId)
          this.getAllProvincesCountries(newCountryId)
        }
      },
    },
    created() {
        if (this.$route.params.id ) {
            this.edit = true
        } else {
            this.edit = false
        }
        this.$root.$refs.formInstallationData = this
        // this.$root.$refs.masterInstallationForm = this
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        createInstallation() {
            this.$store.dispatch('masterInstallation/createMasterInstallation', this.master_installation)
            .then(response => {
                //this.master_installation.id = response.id
                helper.showToast('Instalación padre creada correctamente!', 'CheckIcon', 'success')
            })
            .catch(error => {
                helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        updateInstallation() {
            this.setProvinceAndCountry(this.master_installation.country_id, this.master_installation.province_id)

            this.$store.dispatch('masterInstallation/updateMasterInstallation', this.master_installation)
              .then(response => {
                  helper.showToast('Instalación padre editada correctamente!', 'CheckIcon', 'success')
              })
              .catch(error => {
                  helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                  console.log(error)
              })
        },
        handleUpdateFields(updatedFields) {
            this.master_installation = {...this.master_installation, ...updatedFields}
        },
        setProvinceAndCountry(targetCountryId, targetProvinceId) {
            const matchingProvince = this.$store.state.provincesCountries.provinces.find(province => {
              return province.country_id === targetCountryId && province.province_id === targetProvinceId
            })

            if (matchingProvince) {
              this.master_installation.province_country_id = matchingProvince.id
            }
        },
        getAllProvincesCountries(countryId) {
          this.showLoading = true
          this.master_installation.province_id = null

          this.$store
              .dispatch('provincesCountries/getAllProvincesByCountry', {
                countryId: countryId,
              })
              .then(response => {

                this.showLoading = false
              })
              .catch(error => {
                this.showLoading = false
              })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>