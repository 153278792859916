<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <form-header/>
                    <div v-if="master_installation.id">
                        <hr>
                        <b-tabs>
                            <b-tab active title="Datos Instalación">
                                <form-installation-data/>
                            </b-tab>
                            <b-tab v-if="master_installation.id" title="Datos Generales/Riesgos">
                                <form-general-data-and-risks/>
                            </b-tab>
                            <b-tab v-if="master_installation.id" title="Instalaciones">
                                <list-children-installations/>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BTab, BCard, BCol, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {mapState} from "vuex"
import {GEI_MASTERS_INSTALLATIONS_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"

import FormInstallationData from "@/views/gei/masters_installations/form/formInstallationData/formInstallationData"
import FormGeneralDataAndRisks from "@/views/gei/masters_installations/form/generalDataAndRisks/formGeneralDataAndRisks"
import ListChildrenInstallations from "@/views/gei/masters_installations/form/components/listChildrenInstallations"
import FormHeader from "@/views/gei/masters_installations/form/formHeader/formHeader"
import helper from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'masterInstallation/getField',
    mutationType: 'masterInstallation/updateField',
})


export default {
    name: "FormView",
    components: {
        FormHeader,
        BCol,
        BRow,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        FormInstallationData,
        FormGeneralDataAndRisks,
        ListChildrenInstallations
    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallation', ['validation_master_installation']),
        ...mapFields({
            master_installation: 'master_installation',
        }),
    },
    created() {
        this.$store.commit('masterInstallation/RESET_STATE')
        this.$store.commit('masterInstallation/CLEAR_VALIDATION')
        if (this.$route.params.id) {
            this.edit = true
            this.getMasterInstallation()
        } else {
            this.edit = false
        }
        this.$root.$refs.masterInstallationForm = this

    },
    methods: {
        getMasterInstallation() {
            this.showLoading = true
            this.$store.dispatch('masterInstallation/getMasterInstallation', {
                relations: ['responsible', 'clientContractEE', 'clientDelivery', 'segment', 'installationType',
                    'clientInstallationRisk', 'clientEmergency', 'transferCertificate', 'childrenInstallation', 'childrenInstallation.installationType'],
                id: this.$route.params.id ?? this.master_installation.id,
            })
            .then(response => {

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        cancel() {
            this.$router.push(GEI_MASTERS_INSTALLATIONS_LIST)
        },
        createInstallation() {
            this.$store.dispatch('masterInstallation/createMasterInstallation', this.master_installation)
            .then(response => {
             //   this.master_installation.id = response.id
                helper.showToast('Instalación padre creada correctamente!', 'CheckIcon', 'success')

                alert(response)
                this.$router.replace(GEI_MASTERS_INSTALLATIONS_LIST)
                //    this.$root.$refs.masterInstallationForm.getMasterInstallation()


            })
            .catch(error => {
                helper.showToast('Error al crear instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },
        updateInstallation() {
            this.$store.dispatch('masterInstallation/updateMasterInstallation', this.master_installation)
            .then(response => {
                helper.showToast('Instalación padre editada correctamente!', 'CheckIcon', 'success')
            })
            .catch(error => {
                helper.showToast('Error al editar instalación', 'AlertOctagonIcon', 'danger')
                console.log(error)
            })
        },

    },
}
</script>
