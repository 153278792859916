<template>
    <div>
        <div v-if="master_installation.id">
            <b-row sm="12">
                <b-col sm="6">
                    <installation-code-input :installation-code="master_installation.installation_code"
                                             :validation="validation_master_installation.installation_code"
                                             @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="6">
                    <client-input :client="master_installation.client"
                                  :validation="validation_master_installation.client"
                                  @update="handleUpdateFields"></client-input>
                </b-col>
            </b-row>
        </div>

        <div v-if="!master_installation.id">
            <b-row sm="12">
                <b-col sm="3">
                    <segment-id-select :segment-id="master_installation.segment_id"
                                       :validation="validation_master_installation.segment_id"
                                       @update="handleUpdateFields"
                    />
                </b-col>
                <b-col sm="3">
                    <installation-type-id-select :installation-type-id="master_installation.installation_type_id"
                                                 :validation="validation_master_installation.installation_type_id"
                                                 @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="3">
                    <date-start-flat-pickr :date-start="master_installation.start_date"
                                           :validation="validation_master_installation.start_date"
                                           @update="handleUpdateFields"/>
                </b-col>
                <b-col sm="3">
                    <date-end-flat-pickr :date-end="master_installation.end_date"
                                         :validation="validation_master_installation.end_date"
                                         @update="handleUpdateFields"/>
                </b-col>
            </b-row>
            <b-row sm="12">
                <b-col sm="10">
                    <installation-code-concat-input :installation-type-code="master_installation.installation_type_code"
                                             :segment-code="master_installation.segment_code"
                                             :installation-code="master_installation.installation_code"
                                             :installation-id="master_installation.id"
                                             :validation="validation_master_installation.installation_code"
                                             @update="handleUpdateFields"/>
                </b-col>
                <b-col class="mr-1">
                    <b-row sm="12" style="margin-top: 28px">
                        <b-button v-if="!edit" variant="outline-success" block @click="createInstallation()">
                            Crear
                        </b-button>
                        <b-button v-if="edit" variant="outline-success" block @click="updateInstallation()">
                            Guardar
                        </b-button>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import {BCol, BRow, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import segmentIdSelect from "@/views/gei/masters_installations/form/formHeader/components/segmentIdSelect"
import installationTypeIdSelect
    from "@/views/gei/masters_installations/form/formInstallationData/components/installationTypeIdSelect"
import installationCodeInput from "@/views/gei/masters_installations/form/formHeader/components/installationCodeInput"
import installationCodeConcatInput from "@/views/gei/masters_installations/form/formHeader/components/installationCodeConcatInput"
import dateStartFlatPickr
    from "@/views/gei/masters_installations/form/formInstallationData/components/dateStartFlatPickr"
import dateEndFlatPickr from "@/views/gei/masters_installations/form/formInstallationData/components/dateEndFlatPickr"
import clientInput from "@/views/gei/masters_installations/form/formInstallationData/components/clientInput"
import DateStartFlatPickr
    from "@/views/gei/masters_installations/form/formInstallationData/components/dateStartFlatPickr.vue"
import DateEndFlatPickr
    from "@/views/gei/masters_installations/form/formInstallationData/components/dateEndFlatPickr.vue"

import {GEI_MASTERS_INSTALLATIONS_LIST} from "@/router/web/constants"

const {mapFields} = createHelpers({
    getterType: 'masterInstallation/getField',
    mutationType: 'masterInstallation/updateField',
})

export default {
    components: {
        DateEndFlatPickr,
        DateStartFlatPickr,
        clientInput,
        installationCodeInput,
        installationCodeConcatInput,
        installationTypeIdSelect,
        segmentIdSelect,

        BCol,
        BRow,
        BButton,

    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('masterInstallation', ['validation_master_installation']),
        ...mapFields({
            master_installation: 'master_installation',
        }),
    },

    mounted() {
        if (this.$route.params.id) {
            this.edit = true
        } else {
            this.edit = false
        }
    },

    methods: {
        handleUpdateFields(updatedFields) {
            this.master_installation = {...this.master_installation, ...updatedFields}
        },
        createInstallation() {
            this.$root.$refs.masterInstallationForm.createInstallation()
            this.$router.replace(GEI_MASTERS_INSTALLATIONS_LIST)
        },
        updateInstallation() {
            this.$root.$refs.masterInstallationForm.updateInstallation()
        },

    },

}
</script>
