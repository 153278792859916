<template>
    <div>
        <b-card border-variant="primary">
            <div class="d-flex align-items-center justify-content-between mb-1">
                <h5 class="mb-0">Entrega Instalación Cliente</h5>
                <b-button v-if="!disabled && can('delete_gei_master_delivery_installation_client_ee')"
                    variant="outline-success"
                    @click="addRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                        variant="success"
                        class="cursor-pointer "

                    />
                </b-button>
            </div>

            <form-base-document
                :documents-state-data="documents_client_delivery"
                :documents-columns="columns"
                :documents-route="documents_by_route_client_delivery"
                :context="'client-delivery'"
                :is-disabled="disabled"
                :create-permission="can('create_gei_master_delivery_installation_client_ee')"
                :delete-permission="can('delete_gei_master_delivery_installation_client_ee')"
            />
        </b-card>

    </div>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import formBaseDocument
    from "@/views/gei/masters_installations/form/generalDataAndRisks/components/base/formBaseDocument"


const {mapFields} = createHelpers({
    getterType: 'masterInstallationDocuments/getField',
    mutationType: 'masterInstallationDocuments/updateField',
})

export default {
    name: "FormGeneralDocuments",
    components: {
        BButton,
        BCard,
        formBaseDocument
    },
    props: {
        disabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            SpanishLocale,
            columns: [
                {key: 'options', label: 'Acciones', sortable: false},
                {key: 'signature_date', label: 'Fecha Firma', sortable: false},
                {key: 'contract_end_date', label: 'Fecha Fin Contrato', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('masterInstallation', ['master_installation']),
        ...mapState('masterInstallationDocuments', []),
        ...mapFields({
            documents_client_delivery: 'documents_client_delivery',
            documents_by_route_client_delivery: 'documents_by_route_client_delivery',
        }),
    },
    mounted() {
        this.context = 'client-delivery'
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        addRow() {
            const newRow = {
                signature_date: null,
                contract_end_date: null,
                route: this.master_installation.installation_code + '/ENTREGA',
                observations: null,
                options: null,
                master_installation_id: this.master_installation.id,
            }

            this.documents_client_delivery.push(newRow)
            this.documents_client_delivery.editedRow = newRow
        },
    }
}
</script>
