<template>
    <div>
        <b-form-group label="Empresa" label-for="company">
            <v-select
                v-model="companyId"
                class="pt-0" label="name" name="name"
                :reduce="(option) => option.id"
                :options="companiesList"
                :disabled="disabled"
            />

            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import vSelect from "vue-select"

export default {
    components: {
        vSelect,
        BFormGroup,
    },
    props: {
        companyId: {
            type: Number,
            default: null
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            companiesList: [],
            localCompanyId: this.companyId,
        }
    },
    watch: {
        companyId(newVal) {
            this.localCompanyId = newVal
            this.$emit('update', { company_id: this.localCompanyId })
        },
    },
    mounted() {
        this.getCompanies()
    },
    methods:{
        getCompanies() {
            this.showLoading = true

            this.$store.dispatch('companies/getAllCompanies')
            .then(response => {
                this.companiesList = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },
}
</script>
