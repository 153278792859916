<template>
    <div>
        <b-form-group label="Periodo" label-for="period">
            <b-form-input v-model="periodDescription" placeholder="Periodo" :disabled="disabled" autocomplete="off"
                          :class="validation ? 'is-invalid': ''" />
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        periodDescription: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localPeriodDescription: this.periodDescription,
        }
    },
    watch: {
        periodDescription(newVal) {
            this.localPeriodDescription = newVal
            this.$emit('update', { period_description: this.localPeriodDescription })
        },
    },

}
</script>
