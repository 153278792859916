<template>
    <div>
        <b-form-group label="Persona Contacto Cliente" label-for="client_contact_person">
            <b-form-input v-model="clientContactPerson" placeholder="Persona Contacto Cliente" :disabled="disabled"
                          :class="validation ? 'is-invalid': ''"/>
            <small v-if="validation" class="text-danger">
                {{ validation }}</small>
        </b-form-group>
    </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BFormGroup,
        BFormInput,
    },
    props: {
        clientContactPerson: {
            type: String,
            default: ''
        },
        validation: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localClientContactPerson: this.clientContactPerson,
        }
    },
    watch: {
        clientContactPerson(newVal) {
            this.localClientContactPerson = newVal
            this.$emit('update', { client_contact_person: this.localClientContactPerson })
        },
    },

}
</script>
